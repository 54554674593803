import React from 'react';
import Layout from '../components/layout';
import MyNavbar from '../components/navbar/navbar';
import Footer from '../components/footer';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Breadcrumb from "../components/breadcrumb";
import Sidebar from '../components/sidebar';
import Cta from "../components/cta";

import main_img from "../images/malning/malning_2.jpg";
import img_1 from '../images/malning/malning_3.jpg';
import img_2 from '../images/malning/malning_1.jpg';
import img_3 from '../images/malning/malning_4.jpg';
import favicon from '../images/favicon.ico';

function Malning() {
    return (
        <Layout>
            <Helmet>
                <title>Målning - Danas Plåt &amp; Smide AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi utför in- och utvändig målning samt behandling av industrilokaler. Vi utför rostskyddsmålning där vi mäter ytskicken med kalibrerade mätare och lämnar kvalitetsintyg." />
                <meta name="keywords" content="Målning, Industrimålning, Rostskyddsmålning, behandling" />
                <meta property="og:title" content="Målning - Danas Plåt och Smide AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://danasplat.se/malning" />
                <link rel="cannonical" href="https://danasplat.se/malning" />
            </Helmet>

            <MyNavbar active_page="" />
            <section>
                <div className="h-60 md:h-96 w-screen bg-malning bg-center bg-no-repeat bg-cover">
                    <div className="h-60 md:h-96 w-screen  bg-opacity-50 bg-black flex justify-center items-center ">
                        <h1 className="text-4xl sm:text-5xl md:text-7xl font-extrabold text-center text-white">Rostskyddsmålning &amp; Industrimålning</h1>
                    </div>
                </div>
                <Breadcrumb page="Målning"/>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24">
                    <div className="grid lg:grid-cols-2 gap-24 lg:gap-10">
                        <div className="bg-white">
                            <div className="w-full">
                                <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-left text-green-900">Industrimålning och rostskyddsmålning</h2>
                                <p className="lg:text-xl text-gray-600 font-bold mt-12">Industrimålning</p>
                                <p className="lg:text-xl text-gray-600">Vi utför in- och utvändig behandling av industrilokaler. Det utförs noggrann rengöring och tvättning inför målning av industrilokaler för att uppnå ett bra slutresultat.</p>
                                <p className="lg:text-xl text-gray-600 font-bold mt-12">Rostskyddsmålning</p>
                                <p className="lg:text-xl text-gray-600">Rostskyddsmålning utförs på produkter som ska ha en viss färg och målningssystemen anpassas för att klara av den miljö där produkten finns och ska vistas, hållbart och kvalitativt. Vi har en egen lackbox där vi använder våtfärg som vi kan blanda själva till valfri ral kulör. Vi utför rostskyddsmålning där vi mäter ytskicken med kalibrerade mätare och lämnar kvalitetsintyg.</p>
                                <p className="lg:text-xl text-gray-600 mt-6">Välkommen att kontakta oss!</p>
                                <Link to="/kontakt" className="block mt-12">
                                    <span className="bg-red-700 shadow-xl text-white text-md md:text-xl py-4 px-6 rounded hover:bg-red-800">Kontakta oss</span>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full h-full">
                            <div className="w-full h-full bg-image-square bg-no-repeat bg-custome bg-right-bottom">
                                <div className="w-full h-full bg-image-circel bg-no-repeat bg-custome bg-left-top p-4 lg:p-8">
                                    <img alt="Industrimålning" className="w-full rounded-tl-3xl rounded-br-3xl rounded-tr-md rounded-bl-md  shadow-xl object-cover h-full object-center block" src={main_img} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pt-24 pb-32">
                    <hr className=""/>
                </div>
            </section>

            <section>
                <div className="container mx-auto px-4 pb-24">
                    <div className="grid grid-cols-12 gap-10">
                        <div className="col-span-full lg:col-span-3">
                            <Sidebar />
                        </div>
                        <div className="col-span-full lg:col-span-9">
                            <div className="w-full grid lg:grid-cols-2 gap-10">
                                <img alt="rostskyddsmålning i Södertälje" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_1} />
                                <img alt="Industrimålning" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_2} />
                                <img alt="målning av industrilokaler" className="w-full rounded-md shadow-md object-cover h-full object-center block" src={img_3} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Cta />
            <Footer />
        </Layout>
    );
}
export default Malning;